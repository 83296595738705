import axios from 'axios';

const API_URL = import.meta.env.VITE_API_URL || '/api';

export const fetchProperties = async () => {
  try {
    const response = await axios.get(`${API_URL}/property-data`);
    return response.data;
  } catch (error) {
    console.error('Error fetching properties:', error);
    throw error;
  }
};

export const formatPrice = (price) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0
  }).format(price);
};

export const calculatePriceChange = (oldPrice, newPrice) => {
  if (!oldPrice || !newPrice) return null;
  const change = newPrice - oldPrice;
  const percentage = (change / oldPrice) * 100;
  return {
    change,
    percentage: Math.round(percentage * 10) / 10
  };
}; 