import React, { useMemo, useState } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar
} from 'recharts';
import { PropertyScoring } from '../services/PropertyScoring';
import '../styles/AnalyticsDashboard.css';
import { Bot, ChevronDown, ChevronUp } from 'lucide-react';

const AnalyticsDashboard = ({ properties, isOpen, onClose }) => {
  const [showInsights, setShowInsights] = useState(false);

  const analytics = useMemo(() => {
    if (!properties?.length) return null;

    // Core metrics
    const totalProperties = properties.length;
    const avgPrice = properties.reduce((sum, p) => sum + p.base_price, 0) / totalProperties;
    
    // Price reduction analysis
    const priceReducedProps = properties.filter(p => (p.base_info?.price_change || 0) < 0);
    const avgPriceReduction = properties.reduce((sum, p) => 
      sum + Math.abs(p.base_info?.price_change || 0), 0
    ) / totalProperties;
    const priceReducedCount = priceReducedProps.length;
    
    // Days on market analysis
    const avgDaysOnMarket = Math.round(
      properties.reduce((sum, p) => sum + (p.base_info?.days_on_zillow || 0), 0) / totalProperties
    );
    const newListings = properties.filter(p => (p.base_info?.days_on_zillow || 0) <= 7).length;
    
    // Opportunity analysis
    const goldenOpportunities = properties.filter(p => 
      PropertyScoring.calculateOpportunityScore(p) > 75 && 
      (p.base_info?.price_change || 0) < 0
    ).length;

    // Price distribution with percentages
    const priceRanges = [
      { range: '<500k', count: 0, percentage: 0 },
      { range: '500k-750k', count: 0, percentage: 0 },
      { range: '750k-1M', count: 0, percentage: 0 },
      { range: '1M+', count: 0, percentage: 0 }
    ];

    properties.forEach(p => {
      if (p.base_price < 500000) priceRanges[0].count++;
      else if (p.base_price < 750000) priceRanges[1].count++;
      else if (p.base_price < 1000000) priceRanges[2].count++;
      else priceRanges[3].count++;
    });

    // Calculate percentages
    priceRanges.forEach(range => {
      range.percentage = (range.count / totalProperties) * 100;
    });

    // Sophisticated market score calculation
    const marketScore = Math.round(
      properties.reduce((sum, property) => {
        const baseScore = PropertyScoring.calculateOpportunityScore(property);
        const daysOnMarket = property.base_info?.days_on_zillow || 0;
        const priceChange = property.base_info?.price_change || 0;
        const priceChangePercent = priceChange / property.base_price;
        
        // Base score is already 0-100
        // Boost score for golden opportunities
        const adjustedBaseScore = baseScore >= 75 ? baseScore * 1.3 : baseScore;

        // Normalize and weight individual factors (0-100 scale for each)
        const recencyScore = Math.max(0, 100 * (1 - (daysOnMarket / 30))); // Full points for < 30 days
        const priceScore = priceChangePercent < 0 
          ? Math.min(100, Math.abs(priceChangePercent * 100) * 20) // Up to 100 points for 5% or more reduction
          : 0;
        
        // Weight distribution (total = 100%)
        const weights = {
          baseScore: 0.7,    // 70% base opportunity score (increased from 50%)
          recency: 0.15,     // 15% recency (decreased from 30%)
          price: 0.15        // 15% price reduction (decreased from 20%)
        };
        
        // Calculate weighted score (0-100 scale)
        const weightedScore = Math.min(100, // Cap at 100
          (adjustedBaseScore * weights.baseScore) +
          (recencyScore * weights.recency) +
          (priceScore * weights.price)
        );
        
        return sum + weightedScore;
      }, 0) / properties.length
    );

    return {
      totalProperties,
      avgPrice,
      priceReducedCount,
      avgPriceReduction,
      goldenOpportunities,
      avgDaysOnMarket,
      newListings,
      priceRanges,
      marketScore
    };
  }, [properties]);

  if (!analytics) return null;

  return (
    <>
      <div className={`analytics-dashboard ${isOpen ? 'open' : ''}`}>
        <div className="dashboard-content">
          <div className="dashboard-header">
            <h2>Market Analytics</h2>
            <span className="performance-badge">
              {analytics.marketScore}% Market Score
            </span>
          </div>

          <div className="insights-section">
            <div 
              className="insights-header"
              onClick={() => setShowInsights(!showInsights)}
            >
              <Bot size={18} />
              <span>AI Market Summary</span>
              {showInsights ? (
                <ChevronUp size={16} className="chevron" />
              ) : (
                <ChevronDown size={16} className="chevron" />
              )}
            </div>
            {showInsights && (
              <p className="insights-content">
                {`The market currently shows ${analytics.priceReducedCount} price-reduced properties out of ${analytics.totalProperties} listings, with an average price reduction of $${Math.round(analytics.avgPriceReduction).toLocaleString()}. Properties are staying on market for an average of ${analytics.avgDaysOnMarket} days, with ${analytics.newListings} new listings in the past week. ${analytics.goldenOpportunities} properties qualify as golden opportunities, representing ${((analytics.goldenOpportunities / analytics.totalProperties) * 100).toFixed(1)}% of current inventory.`}
              </p>
            )}
          </div>

          <div className="metric-tile">
            <div className="metric-header">Active Listings</div>
            <div className="metric-content">
              <div className="metric-value">{analytics.totalProperties}</div>
              <div className="metric-badge">
                {analytics.priceReducedCount} Price Reduced
              </div>
            </div>
          </div>

          <div className="metric-tile">
            <div className="metric-header">Average Price</div>
            <div className="metric-content">
              <div className="metric-value">${Math.round(analytics.avgPrice).toLocaleString()}</div>
              {analytics.avgPriceReduction > 0 && (
                <div className="metric-badge positive">
                  ${Math.round(analytics.avgPriceReduction).toLocaleString()} avg reduction
                </div>
              )}
            </div>
          </div>

          <div className="metric-tile highlight">
            <div className="metric-header">Golden Opportunities</div>
            <div className="metric-content">
              <div className="metric-value">{analytics.goldenOpportunities}</div>
              <div className="metric-badge positive">
                {((analytics.goldenOpportunities / analytics.totalProperties) * 100).toFixed(1)}% of listings
              </div>
            </div>
          </div>

          <div className="metric-tile">
            <div className="metric-header">Average Days on Market</div>
            <div className="metric-content">
              <div className="metric-value">{analytics.avgDaysOnMarket} days</div>
              <div className="metric-badge">
                {analytics.newListings} new listings
              </div>
            </div>
          </div>

          <div className="chart-section">
            <h3>Price Distribution</h3>
            <ResponsiveContainer width="100%" height={200}>
              <AreaChart 
                data={analytics.priceRanges}
                margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorCount" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#c700ff" stopOpacity={0.2}/>
                    <stop offset="95%" stopColor="#c700ff" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid 
                  strokeDasharray="3 3" 
                  stroke="#eee" 
                  vertical={false}
                />
                <XAxis 
                  dataKey="range" 
                  stroke="#64748B"
                  fontSize={11}
                  tickLine={false}
                  axisLine={false}
                  dy={5}
                />
                <YAxis 
                  stroke="#64748B"
                  fontSize={11}
                  tickLine={false}
                  axisLine={false}
                  dx={-5}
                  tickFormatter={(value) => `${value.toFixed(0)}%`}
                />
                <Tooltip 
                  contentStyle={{
                    background: 'rgba(255, 255, 255, 0.98)',
                    border: 'none',
                    borderRadius: '8px',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                    fontSize: '11px',
                    padding: '8px 12px'
                  }}
                  labelStyle={{
                    color: '#64748B',
                    fontWeight: 500,
                    marginBottom: '4px'
                  }}
                  itemStyle={{
                    color: '#1E293B',
                    padding: '0'
                  }}
                  formatter={(value) => [`${value.toFixed(1)}%`, '']}
                />
                <Area 
                  type="monotone" 
                  dataKey="percentage"
                  stroke="#c700ff"
                  strokeWidth={2}
                  fill="url(#colorCount)"
                  dot={{
                    stroke: '#c700ff',
                    strokeWidth: 2,
                    r: 4,
                    fill: 'white'
                  }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
        <div className="analytics-close-tab" onClick={onClose}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 19l-7-7 7-7" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </div>
      </div>
    </>
  );
};

export default AnalyticsDashboard; 