import { PropertyScoring } from './PropertyScoring';

export const MARKER_STYLES = {
  default: {
    path: window.google?.maps.SymbolPath.CIRCLE,
    scale: 12,
    fillColor: '#546E7A',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeWeight: 2
  },
  dimmed: {
    path: window.google?.maps.SymbolPath.CIRCLE,
    scale: 10,
    fillColor: '#546E7A',
    fillOpacity: 0.6,
    strokeColor: '#FFFFFF',
    strokeWeight: 1.5
  },
  active: {
    path: 'M 0,0 C -1,-10 -5,-11 -5,-15 A 5,5 0 1,1 5,-15 C 5,-11 1,-10 0,0',
    scale: 2.2,
    fillColor: '#1E88E5',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeWeight: 2,
    anchor: new google.maps.Point(0, 0),
    labelOrigin: new google.maps.Point(0, -20)
  },
  priceReduced: {
    path: window.google?.maps.SymbolPath.CIRCLE,
    scale: 12,
    fillColor: '#9C27B0',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeWeight: 2
  },
  golden: {
    path: window.google?.maps.SymbolPath.CIRCLE,
    scale: 12,
    fillColor: '#FFD700',
    fillOpacity: 1.0,
    strokeColor: '#FFFFFF',
    strokeWeight: 2
  }
};

export class MarkerManager {
  constructor(map) {
    this.map = map;
    this.markers = new Map();
    this.activeInfoWindow = null;
    this.activeCompMarkers = [];
  }

  initializeMarkers(properties, onPropertySelect) {
    // Clear existing markers
    this.clearAllMarkers();

    properties.forEach(property => {
      if (!property.location?.lat || !property.location?.lng) return;

      const isGoldenOpportunity = 
        PropertyScoring.calculateOpportunityScore(property) > 74;
      
      const hasPriceReduction = 
        !isGoldenOpportunity && // Only check if not golden
        property.base_info?.price_change !== null && 
        property.base_info?.price_change < 0;

      const isNewListing = 
        !isGoldenOpportunity && // Only check if not golden
        property.base_info?.days_on_zillow <= 7;

      const marker = new window.google.maps.Marker({
        position: {
          lat: Number(property.location.lat),
          lng: Number(property.location.lng)
        },
        map: this.map,
        title: `$${property.base_price.toLocaleString()}`,
        icon: isGoldenOpportunity ? MARKER_STYLES.golden :
              isNewListing ? {
                path: window.google.maps.SymbolPath.CIRCLE,
                scale: 12,
                fillColor: '#00BCD4',
                fillOpacity: 1.0,
                strokeColor: '#FFFFFF',
                strokeWeight: 2
              } :
              hasPriceReduction ? MARKER_STYLES.priceReduced :
              MARKER_STYLES.default
      });

      marker.set('zpid', property.zpid);
      marker.set('isGolden', isGoldenOpportunity);
      marker.set('isPriceReduced', hasPriceReduction);
      marker.set('isNewListing', isNewListing);

      marker.addListener('click', () => {
        this.handleMarkerClick(property, marker, onPropertySelect);
      });

      this.markers.set(property.zpid, marker);
    });
  }

  handleMarkerClick(property, marker, onPropertySelect) {
    // Clear existing comp markers
    this.clearCompMarkers();
    
    // Hide all other markers
    this.markers.forEach(baseMarker => {
      if (baseMarker !== marker) {
        baseMarker.setVisible(false);
      }
    });

    // Highlight selected marker with animation
    marker.setIcon(MARKER_STYLES.active);
    marker.setZIndex(1000); // Ensure selected marker is on top
    marker.setAnimation(google.maps.Animation.DROP); // Add animation
    this.activeInfoWindow = marker;

    // Create comp markers
    this.createCompMarkers(property);

    // Pan and zoom to location
    this.map.panTo(marker.getPosition());
    this.smoothZoom(this.map, 15, this.map.getZoom());

    if (typeof onPropertySelect === 'function') {
      onPropertySelect(property);
    }
  }

  createCompMarkers(property) {
    const sortedComps = [...property.comp_info].sort((a, b) => b.comp_price - a.comp_price);
    const topCompZpids = new Set(sortedComps.slice(0, 2).map(comp => comp.comp_zpid));

    property.comp_info.forEach(comp => {
      const compPosition = {
        lat: Number(comp.comp_latitude),
        lng: Number(comp.comp_longitude)
      };

      if (isNaN(compPosition.lat) || isNaN(compPosition.lng)) {
        console.warn(`Invalid coordinates for comp ${comp.comp_zpid}`);
        return;
      }

      const isTopComp = topCompZpids.has(comp.comp_zpid);
      
      const compMarker = new window.google.maps.Marker({
        position: compPosition,
        map: this.map,
        title: `$${comp.comp_price.toLocaleString()}`,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 8,
          fillColor: isTopComp ? '#43A047' : '#78909C',
          fillOpacity: 1.0,
          strokeColor: '#FFFFFF',
          strokeWeight: 2
        },
        zIndex: isTopComp ? 2 : 1
      });

      compMarker.set('comp_zpid', comp.comp_zpid);
      compMarker.set('isTopComp', isTopComp);

      this.activeCompMarkers.push(compMarker);
    });
  }

  smoothZoom(map, targetZoom, currentZoom) {
    if (currentZoom !== targetZoom) {
      const z = window.google.maps.event.addListener(map, 'zoom_changed', () => {
        window.google.maps.event.removeListener(z);
        this.smoothZoom(map, targetZoom, map.getZoom());
      });
      
      const step = targetZoom > currentZoom ? 1 : -1;
      setTimeout(() => map.setZoom(currentZoom + step), 150);
    }
  }

  clearCompMarkers() {
    this.activeCompMarkers.forEach(marker => marker.setMap(null));
    this.activeCompMarkers = [];
  }

  clearAllMarkers() {
    this.clearCompMarkers();
    this.markers.forEach(marker => marker.setMap(null));
    this.markers = new Map();
    this.activeInfoWindow = null;
  }

  showPropertyDetail(property) {
    if (!property) return;

    // Find the marker for this property
    const marker = this.markers.get(property.zpid);
    if (!marker) return;

    // Simulate a marker click to show property details
    this.handleMarkerClick(property, marker, () => {});
  }

  showAllProperties(properties) {
    if (this.activeInfoWindow) {
      this.activeInfoWindow.setAnimation(null);
      this.activeInfoWindow = null;
    }

    this.clearCompMarkers();

    this.markers.forEach((marker) => {
      const property = properties.find(p => p.zpid === marker.get('zpid'));
      
      if (property) {
        marker.setVisible(true);
        marker.setAnimation(null);
        
        if (marker.get('isGolden')) {
          marker.setIcon(MARKER_STYLES.golden);
        } else if (marker.get('isNewListing')) {
          marker.setIcon({
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 12,
            fillColor: '#00BCD4',
            fillOpacity: 1.0,
            strokeColor: '#FFFFFF',
            strokeWeight: 2
          });
        } else if (marker.get('isPriceReduced')) {
          marker.setIcon(MARKER_STYLES.priceReduced);
        } else {
          marker.setIcon(MARKER_STYLES.default);
        }
      } else {
        marker.setVisible(false);
      }
    });

    if (properties.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      properties.forEach(property => {
        const marker = this.markers.get(property.zpid);
        if (marker && marker.getVisible()) {
          bounds.extend(marker.getPosition());
        }
      });
      this.map.fitBounds(bounds);
    }
  }
} 