import React, { useState } from 'react';
import { formatPrice } from '../services/propertyService';
import { PropertyScoring } from '../services/PropertyScoring';
import '../styles/PropertyDetail.css';

const PropertyDetail = ({ 
  property, 
  onClose, 
  filteredProperties, 
  mapRef,
  sortField,
  sortDirection,
  isComp = false,
  onCompSelect
}) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  if (!property) return null;

  const {
    base_price,
    base_info,
    imgSrc,
    images = [],
    comp_info = [],
    golden_opportunity,
    detailUrl,
    comp_price,
    comp_imgSrc,
    comp_address,
    comp_bedrooms,
    comp_bathrooms,
    comp_livingArea,
    comp_dateSold,
    comp_distance,
    comp_detailUrl,
    comp_zpid
  } = property;

  const priceChange = base_info?.price_change;
  const percentOfMax = base_info?.percent_of_max_comp ? (base_info.percent_of_max_comp * 100).toFixed(1) : null;
  const daysOnMarket = base_info?.days_on_zillow;
  const compCount = base_info?.sold_comparable_count;

  // Move sortedComps and topCompZpids outside the if block
  const sortedComps = [...comp_info].sort((a, b) => b.comp_price - a.comp_price);
  const topCompZpids = new Set(sortedComps.slice(0, 2).map(comp => comp.comp_zpid));

  // Only calculate scores for main property view
  let opportunityScore, confidenceScore, valuePosition, marketPosition, propertyOptimization;
  
  if (!isComp) {
    // Calculate scores only for main property
    opportunityScore = PropertyScoring.calculateOpportunityScore(property);
    confidenceScore = PropertyScoring.calculateConfidenceScore(property);
    valuePosition = PropertyScoring.calculateValuePosition(property);
    marketPosition = PropertyScoring.calculateMarketPosition(property);
    propertyOptimization = PropertyScoring.calculatePropertyOptimization(property);
  }

  const nextImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const previousImage = (e) => {
    e.stopPropagation();
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const imageCarousel = (
    <div className="image-carousel-container">
      <div className="image-carousel">
        {images.length > 0 && images.map((image, index) => (
          <div 
            key={index}
            className={`carousel-slide ${index === currentImageIndex ? 'active' : ''} ${
              index === currentImageIndex - 1 ? 'prev' : 
              index === currentImageIndex + 1 ? 'next' : ''
            }`}
          >
            <img 
              src={image} 
              alt={`Property view ${index + 1}`}
              className="carousel-image"
            />
          </div>
        ))}
      </div>
      {images.length > 1 && (
        <>
          <button className="carousel-button prev" onClick={previousImage}>‹</button>
          <button className="carousel-button next" onClick={nextImage}>›</button>
          <div className="carousel-indicators">
            {images.map((_, index) => (
              <span 
                key={index} 
                className={`indicator ${index === currentImageIndex ? 'active' : ''}`}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentImageIndex(index);
                }}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );

  const createPriceDistribution = () => {
    const allPrices = [base_price, ...comp_info.map(comp => comp.comp_price)];
    const minPrice = Math.min(...allPrices);
    const maxPrice = Math.max(...allPrices);
    const medianPrice = calculateMedian(allPrices);
    const range = maxPrice - minPrice;

    // Calculate positions as percentages
    const basePosition = ((base_price - minPrice) / range) * 100;
    const medianPosition = ((medianPrice - minPrice) / range) * 100;
    const maxPosition = 100;

    // Check for overlaps (if tags are within 20% of each other)
    const hasOverlap = (pos1, pos2) => Math.abs(pos1 - pos2) < 20;
    
    const medianOverlapsBase = hasOverlap(medianPosition, basePosition);
    const medianOverlapsMax = hasOverlap(medianPosition, maxPosition);
    const baseOverlapsMax = hasOverlap(basePosition, maxPosition);

    return (
      <div className="distribution-container">
        <div className="distribution-line"></div>
        {comp_info.map(comp => {
          const position = ((comp.comp_price - minPrice) / range) * 100;
          return (
            <div
              key={comp.comp_zpid}
              className="price-dot comp-dot"
              style={{ left: `${position}%` }}
              title={`$${comp.comp_price.toLocaleString()}`}
            />
          );
        })}
        <div
          className="price-dot base-dot"
          style={{ left: `${basePosition}%` }}
          title={`$${base_price.toLocaleString()}`}
        >
          <div className="price-tag">
            <div className="price-tag-label">This home</div>
            <div className="price-tag-value">{formatPrice(base_price)}</div>
          </div>
        </div>
        <div
          className={`price-dot median-dot ${medianOverlapsBase || medianOverlapsMax ? 'raised' : ''}`}
          style={{ left: `${medianPosition}%` }}
          title={`Median: $${medianPrice.toLocaleString()}`}
        >
          <div className="price-tag">
            <div className="price-tag-label">Median</div>
            <div className="price-tag-value">{formatPrice(medianPrice)}</div>
          </div>
        </div>
        <div
          className="price-dot max-dot"
          style={{ left: `${maxPosition}%` }}
          title={`Max: $${maxPrice.toLocaleString()}`}
        >
          <div className="price-tag">
            <div className="price-tag-label">Max</div>
            <div className="price-tag-value">{formatPrice(maxPrice)}</div>
          </div>
        </div>
        <div className="distribution-labels">
          <span>${minPrice.toLocaleString()}</span>
          <span>${maxPrice.toLocaleString()}</span>
        </div>
      </div>
    );
  };

  const calculateMedian = (prices) => {
    const sorted = [...prices].sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);
    return sorted.length % 2 === 0
      ? (sorted[middle - 1] + sorted[middle]) / 2
      : sorted[middle];
  };

  const handleBackToProperty = () => {
    if (isComp) {
      onClose(false);
    } else {
      onClose();
    }
  };

  return (
    <div className="property-detail">
      <div className="property-detail-header">
        <button className="back-button" onClick={handleBackToProperty}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" fill="currentColor"/>
          </svg>
          {isComp ? 'Back to Property' : 'Back to List'}
        </button>
      </div>
      
      <div className="property-detail-content">
        <div className="main-property-info">
          <div className="image-section">
            {!isComp && priceChange && priceChange < 0 && (
              <div className="price-cut-banner">
                Price cut: {formatPrice(Math.abs(priceChange))}
              </div>
            )}
            <a 
              // href={`https://www.zillow.com/homedetails/${isComp ? comp_zpid : property.zpid}`}
              href={`${isComp ? comp_detailUrl : detailUrl}`}
              target="_blank" 
              rel="noopener noreferrer"
              className="view-on-zillow"
            >
              View on Zillow
            </a>
            {isComp ? (
              <img 
                src={comp_imgSrc || 'placeholder.jpg'}
                className="main-property-image" 
                alt="Comparable Property"
              />
            ) : (
              imageCarousel
            )}
          </div>

          <div className="property-header">
            <h1 className="property-price">{formatPrice(isComp ? comp_price : base_price)}</h1>
            <div className="property-basics">
              <span className="beds">
                {isComp ? comp_bedrooms : base_info?.bedrooms} beds
              </span>
              <span className="separator">•</span>
              <span className="baths">
                {isComp ? comp_bathrooms : base_info?.bathrooms} baths
              </span>
              <span className="separator">•</span>
              <span className="sqft">
                {isComp ? comp_livingArea?.toLocaleString() : base_info?.living_area?.toLocaleString()} sqft
              </span>
              {!isComp && (
                <>
                  <span className="separator">-</span>
                  <span className="type">
                    {base_info?.property_type === 'SINGLE_FAMILY' ? 'Single Family Home' : 'Townhouse'} for sale
                  </span>
                </>
              )}
            </div>
            <div className="property-address">
              {isComp ? comp_address : base_info?.address}
            </div>
          </div>
        </div>

        {isComp ? (
          <div className="comp-details">
            <div className="section-header">Sale Information</div>
            <div className="stats-grid">
              <div className="stat-item">
                <div className="stat-label">Sale Date</div>
                <div className="stat-value">
                  {comp_dateSold ? new Date(comp_dateSold).toLocaleDateString() : 'N/A'}
                </div>
              </div>
              <div className="stat-item">
                <div className="stat-label">Price/sqft</div>
                <div className="stat-value">
                  {formatPrice(Math.round(comp_price / comp_livingArea))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="section-header">Price Distribution</div>
            <div className="price-distribution">
              {createPriceDistribution()}
            </div>

            <div className="section-header">Property Analysis</div>
            <div className="analysis-card">
              <div className="score-title">Opportunity Score</div>
              <div className="score-section">
                <div className="big-score">{Math.round(opportunityScore)}</div>
                <div className="rating-display">
                  <div className="home-rating">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <span 
                        key={index} 
                        className={`home-icon ${index <= Math.round(confidenceScore / 20) ? 'filled' : 'empty'}`}
                      >
                        <svg viewBox="0 0 24 24" width="24" height="24">
                          <path d="M12 2L3 9v11a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V9l-9-7z" 
                            fill="currentColor" 
                            stroke="currentColor" 
                            strokeWidth="1"
                          />
                        </svg>
                      </span>
                    ))}
                  </div>
                  <div className="rating-text">Based on {compCount} comparables</div>
                </div>
              </div>

              <div className="metrics-section">
                <div className="metric-item">
                  <div className="metric-header">
                    <span>Value Position</span>
                    <span className="metric-score">{Math.round((valuePosition / 65) * 100)}/100</span>
                  </div>
                  <div className="metric-bar">
                    <div 
                      className="metric-fill reliability"
                      style={{ width: `${(valuePosition / 65) * 100}%` }}
                    />
                  </div>
                </div>
                <div className="metric-item">
                  <div className="metric-header">
                    <span>Market Position</span>
                    <span className="metric-score">{Math.round((marketPosition / 25) * 100)}/100</span>
                  </div>
                  <div className="metric-bar">
                    <div 
                      className="metric-fill payout"
                      style={{ width: `${(marketPosition / 25) * 100}%` }}
                    />
                  </div>
                </div>
                <div className="metric-item">
                  <div className="metric-header">
                    <span>Property Optimization</span>
                    <span className="metric-score">{Math.round(propertyOptimization * 10)}/100</span>
                  </div>
                  <div className="metric-bar">
                    <div 
                      className="metric-fill solutions"
                      style={{ width: `${propertyOptimization * 10}%` }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="section-header">Property Details</div>
            <div className="property-stats-container">
              <div className="stats-grid">
                <div className="stat-item">
                  <div className="stat-label">Price/sqft</div>
                  <div className="stat-value">${Math.round(base_info?.price_per_sqft).toLocaleString()}</div>
                </div>
                <div className="stat-item">
                  <div className="stat-label">Days on Market</div>
                  <div className="stat-value">{daysOnMarket}</div>
                </div>
              </div>

              <div className="section-header">Comparative Analysis</div>
              <div className="stats-grid">
                <div className="stat-item">
                  <div className="stat-label">% of Max Comp</div>
                  <div className="stat-value">{percentOfMax}%</div>
                </div>
                <div className="stat-item">
                  <div className="stat-label">Price/sqft %</div>
                  <div className="stat-value">{(base_info?.percent_of_sqft * 100).toFixed(1)}%</div>
                </div>
                <div className="stat-item">
                  <div className="stat-label">Max Comp Price</div>
                  <div className="stat-value">{formatPrice(base_info?.comp_max_price)}</div>
                </div>
                <div className="stat-item">
                  <div className="stat-label">Comparable Sales</div>
                  <div className="stat-value">{compCount}</div>
                </div>
              </div>

              <div className="section-header">Comparable Properties</div>
              <div className="comp-grid">
                {sortedComps.map(comp => (
                  <div 
                    key={comp.comp_zpid}
                    className={`comp-card ${topCompZpids.has(comp.comp_zpid) ? 'top-comp' : ''}`}
                    onClick={() => onCompSelect?.({
                      ...comp,
                      mainProperty: property
                    })}
                  >
                    <img 
                      src={comp.comp_imgSrc || 'placeholder.jpg'} 
                      className="comp-image" 
                      alt="Comparable Property"
                    />
                    <div className="comp-info">
                      <div className="comp-price">{formatPrice(comp.comp_price)}</div>
                      <div className="comp-details">
                        {comp.comp_bedrooms} beds, {comp.comp_bathrooms} baths
                        <br />
                        {comp.comp_livingArea.toLocaleString()} sqft
                        {comp.comp_dateSold && (
                          <><br />Sold: {new Date(comp.comp_dateSold).toLocaleDateString()}</>
                        )}
                      </div>
                      {topCompZpids.has(comp.comp_zpid) && (
                        <div className="top-comp-badge">Top Comparable</div>
                      )}
                    </div>
                    <a 
                      href={`${comp.comp_detailUrl}`}
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="comp-link"
                      onClick={e => e.stopPropagation()}
                    >
                      View on Zillow
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PropertyDetail; 