import { useState, useEffect, useRef } from 'react';
import { ThemeProvider, createTheme } from '@mui/material';
import NotificationsPanel from './components/NotificationsPanel';
import PropertyList from './components/PropertyList';
import PropertyDetail from './components/PropertyDetail.jsx';
import Map from './components/Map';
import { fetchProperties } from './services/propertyService';
import './styles/App.css';
import './styles/NotificationsPanel.css';
import './styles/PropertyDetail.css';
import HKLogo from './assets/logo.png';
import launchcoLogo from './assets/launchco-logo.png';
import AnalyticsDashboard from './components/AnalyticsDashboard';

const theme = createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
    button: {
      fontFamily: '"Poppins", sans-serif',
    },
    h1: {
      fontFamily: '"Poppins", sans-serif',
    },
    h2: {
      fontFamily: '"Poppins", sans-serif',
    },
    h3: {
      fontFamily: '"Poppins", sans-serif',
    },
    h4: {
      fontFamily: '"Poppins", sans-serif',
    },
    h5: {
      fontFamily: '"Poppins", sans-serif',
    },
    h6: {
      fontFamily: '"Poppins", sans-serif',
    },
    body1: {
      fontFamily: '"Poppins", sans-serif',
    },
    body2: {
      fontFamily: '"Poppins", sans-serif',
    },
  },
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPropertyDetail, setShowPropertyDetail] = useState(false);
  const mapRef = useRef(null);
  const [sortField, setSortField] = useState('score');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filters, setFilters] = useState({
    minPrice: '',
    maxPrice: '',
    minSqft: '',
    maxSqft: '',
    minComps: '',
    minScore: '',
    maxScore: '',
    minDays: '',
    maxDays: '',
    priceReduced: false,
    goldenOpportunities: false,
    newListings: false
  });
  const [activeTab, setActiveTab] = useState('listings');
  const [isViewingComp, setIsViewingComp] = useState(false);
  const [previousProperty, setPreviousProperty] = useState(null);
  const [legendFilter, setLegendFilter] = useState(null);
  const [limit, setLimit] = useState(() => {
    const defaults = JSON.parse(localStorage.getItem('propertyListDefaults'));
    return defaults?.limit || 100;
  });
  const [showAnalytics, setShowAnalytics] = useState(false);

  useEffect(() => {
    const loadProperties = async () => {
      try {
        const data = await fetchProperties();
        setProperties(data);
        setFilteredProperties(data);
        setLoading(false);
      } catch (error) {
        console.error('Error loading properties:', error);
        setLoading(false);
      }
    };

    loadProperties();
  }, []);

  const handlePropertySelect = (property, isComp = false) => {
    setSelectedProperty(property);
    setShowPropertyDetail(true);
    setIsViewingComp(isComp);
    setPreviousProperty(property);
  };

  const handleClosePropertyDetail = () => {
    setShowPropertyDetail(false);
    setSelectedProperty(null);
    
    // Update the map markers
    const markerManager = mapRef.current?.getMarkerManager();
    if (markerManager) {
      // Use setTimeout to ensure state updates have completed
      setTimeout(() => {
        markerManager.showAllProperties(filteredProperties);
      }, 0);
    }
  };

  const handleSortChange = (field, direction) => {
    setSortField(field);
    setSortDirection(direction);
  };

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  const resetToDefaults = () => {
    setSelectedProperty(null);
    setShowPropertyDetail(false);
    setActiveTab('listings');
    setSortField('score');
    setSortDirection('desc');
    setLimit(100);
    setFilters({
      minPrice: '',
      maxPrice: '',
      minSqft: '',
      maxSqft: '',
      minComps: '',
      minScore: '',
      maxScore: '',
      minDays: '',
      maxDays: '',
      priceReduced: false,
      goldenOpportunities: false,
      newListings: false
    });
    setIsViewingComp(false);
    setPreviousProperty(null);
    
    // Reset map markers
    const markerManager = mapRef.current?.getMarkerManager();
    if (markerManager) {
      setTimeout(() => {
        markerManager.showAllProperties(properties);
      }, 0);
    }
  };

  const handleLegendClick = (type) => {
    // Toggle filter off if clicking the same type
    setLegendFilter(current => current === type ? null : type);
  };

  const handleLegendFilterReset = () => {
    setLegendFilter(null);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner">
          <div className="spinner-ring"></div>
          <div className="spinner-ring"></div>
          <div className="spinner-ring"></div>
          <div className="loading-text">Loading Properties</div>
        </div>
      </div>
    );
  }

  const renderMainContent = () => {
    const content = showPropertyDetail && selectedProperty ? (
      <PropertyDetail 
        property={selectedProperty}
        onClose={(returnToProperty) => {
          if (returnToProperty === false && isViewingComp) {
            setIsViewingComp(false);
            setSelectedProperty(previousProperty);
          } else {
            handleClosePropertyDetail();
          }
        }}
        filteredProperties={filteredProperties}
        mapRef={mapRef}
        sortField={sortField}
        sortDirection={sortDirection}
        isComp={isViewingComp}
        onCompSelect={(comp) => {
          setPreviousProperty(selectedProperty);
          setSelectedProperty(comp);
          setIsViewingComp(true);
        }}
      />
    ) : (
      <PropertyList
        properties={properties}
        selectedProperty={selectedProperty}
        onPropertySelect={handlePropertySelect}
        onFilteredPropertiesChange={setFilteredProperties}
        sortField={sortField}
        sortDirection={sortDirection}
        onSortChange={handleSortChange}
        filters={filters}
        onFiltersChange={handleFiltersChange}
        legendFilter={legendFilter}
        onLegendFilterReset={handleLegendFilterReset}
        limit={limit}
        onLimitChange={setLimit}
      />
    );

    return (
      <>
        <div className="tabs">
          <button 
            className={`tab ${activeTab === 'listings' ? 'active' : ''}`}
            onClick={() => {
              setActiveTab('listings');
              if (showPropertyDetail) {
                handleClosePropertyDetail();
              }
            }}
          >
            Property Listings
          </button>
          <button 
            className={`tab ${activeTab === 'pulse' ? 'active' : ''}`}
            onClick={() => setActiveTab('pulse')}
          >
            Market Pulse
          </button>
        </div>
        {activeTab === 'listings' ? content : (
          <NotificationsPanel 
            properties={properties}
            onPropertySelect={handlePropertySelect}
            onNotificationRead={(notification) => {
              // Handle notification read state if needed
            }}
          />
        )}
      </>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="app">
        <header className="app-header">
          <div className="header-left">
            <img 
              src={HKLogo} 
              alt="Home Keys" 
              className="header-logo" 
              onClick={resetToDefaults}
              style={{ cursor: 'pointer' }}
            />
          </div>
          <div className="header-right">
            <div className="powered-by">
              Powered by
              <img 
                src={launchcoLogo} 
                alt="LaunchCo" 
                className="launchco-logo" 
              />
            </div>
            <button className="header-icon-btn settings">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" fill="currentColor"/>
              </svg>
            </button>
            <button className="header-icon-btn profile">
              <div className="profile-circle">L</div>
            </button>
          </div>
        </header>
        
        <main className="app-main">
          <Map
            properties={filteredProperties}
            selectedProperty={selectedProperty}
            onPropertySelect={handlePropertySelect}
            ref={mapRef}
            onLegendClick={handleLegendClick}
            legendFilter={legendFilter}
          />
          <div className="sidebar">
            {renderMainContent()}
          </div>
        </main>
        <AnalyticsDashboard 
          properties={filteredProperties}
          isOpen={showAnalytics}
          onClose={() => setShowAnalytics(false)}
        />
        <div className="analytics-tab" onClick={() => setShowAnalytics(!showAnalytics)}>
          <svg viewBox="0 0 24 24" fill="currentColor">
            <path d="M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zM9 17H7v-7h2v7zm4 0h-2V7h2v10zm4 0h-2v-4h2v4z"/>
          </svg>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
