import React, { useState, useEffect } from 'react';
import { Bot, ChevronDown, ChevronUp } from 'lucide-react';
import { PropertyScoring } from '../services/PropertyScoring';
import '../styles/NotificationsPanel.css';

const STORAGE_KEY = 'notificationReadStatus';

const NotificationsPanel = ({ 
  properties,
  onPropertySelect,
  onNotificationRead 
}) => {
  const [activeFilter, setActiveFilter] = useState('all');
  const [notifications, setNotifications] = useState([]);
  const [readStatus, setReadStatus] = useState(() => {
    const saved = localStorage.getItem(STORAGE_KEY);
    return saved ? JSON.parse(saved) : {};
  });
  const [selectedNotifications, setSelectedNotifications] = useState(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const [showInsights, setShowInsights] = useState(true);
  
  useEffect(() => {
    // Generate notifications from properties
    const newNotifications = properties.flatMap(property => {
      const notifications = [];
      const score = PropertyScoring.calculateOpportunityScore(property);
      const isNew = property.base_info?.days_on_zillow < 7;
      const priceReduced = property.base_info?.price_change && property.base_info?.price_change < 0;
      const isGolden = score >= 75;

      const notificationKey = (type) => `${property.zpid}-${type}`;

      if (isNew) {
        notifications.push({
          type: 'new',
          title: 'New Listing',
          property,
          time: `${property.base_info?.days_on_zillow} days ago`,
          read: readStatus[notificationKey('new')] || false,
          key: notificationKey('new')
        });
      }

      if (priceReduced) {
        notifications.push({
          type: 'price',
          title: 'Price Reduced',
          property,
          priceChange: property.base_info?.price_change,
          time: `${property.base_info?.days_on_zillow} days ago`,
          read: readStatus[notificationKey('price')] || false,
          key: notificationKey('price')
        });
      }

      if (isGolden) {
        notifications.push({
          type: 'golden',
          title: 'Golden Opportunity',
          property,
          time: `${property.base_info?.days_on_zillow} days ago`,
          read: readStatus[notificationKey('golden')] || false,
          key: notificationKey('golden')
        });
      }

      return notifications;
    });

    setNotifications(newNotifications);
  }, [properties, readStatus]);

  const updateReadStatus = (notificationKey, isRead) => {
    const newReadStatus = {
      ...readStatus,
      [notificationKey]: isRead
    };
    setReadStatus(newReadStatus);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newReadStatus));
  };

  const handleMarkAllRead = () => {
    const newReadStatus = {};
    notifications.forEach(notification => {
      newReadStatus[notification.key] = true;
    });
    setReadStatus(newReadStatus);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newReadStatus));
  };

  const handleMarkAllUnread = () => {
    const newReadStatus = {};
    notifications.forEach(notification => {
      newReadStatus[notification.key] = false;
    });
    setReadStatus(newReadStatus);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newReadStatus));
  };

  const filteredNotifications = notifications.filter(notification => {
    if (activeFilter === 'all') return true;
    if (activeFilter === 'unread') return !notification.read;
    return true;
  });

  const handleNotificationClick = (notification) => {
    updateReadStatus(notification.key, true);
    onPropertySelect(notification.property);
  };

  const formatPrice = (price) => {
    return price ? price.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0
    }) : '$0';
  };

  const handleCheckboxClick = (e, notificationKey) => {
    e.stopPropagation();
    setSelectedNotifications(prev => {
      const newSet = new Set(prev);
      if (newSet.has(notificationKey)) {
        newSet.delete(notificationKey);
      } else {
        newSet.add(notificationKey);
      }
      return newSet;
    });
  };

  const handleSelectAll = (e) => {
    e.stopPropagation();
    if (selectAll) {
      setSelectedNotifications(new Set());
    } else {
      setSelectedNotifications(new Set(filteredNotifications.map(n => n.key)));
    }
    setSelectAll(!selectAll);
  };

  const handleBulkMarkRead = (isRead) => {
    const newReadStatus = { ...readStatus };
    selectedNotifications.forEach(key => {
      newReadStatus[key] = isRead;
    });
    setReadStatus(newReadStatus);
    localStorage.setItem(STORAGE_KEY, JSON.stringify(newReadStatus));
    setSelectedNotifications(new Set());
    setSelectAll(false);
  };

  return (
    <div className="notifications-panel">
      <div className="insights-section">
        <div 
          className="insights-header"
          onClick={() => setShowInsights(!showInsights)}
        >
          <Bot size={18} />
          <span>AI Generated Insights</span>
          {showInsights ? (
            <ChevronUp size={16} className="chevron" />
          ) : (
            <ChevronDown size={16} className="chevron" />
          )}
        </div>
        {showInsights && (
          <p className="insights-content">
            Townhouses in neighborhoods like Eckington and Bloomingdale are attracting investor interest due to their relatively affordable prices and potential for appreciation. Georgetown remains a highly desirable area, but some properties are staying on the market longer, suggesting a potential shift in buyer preferences or pricing expectations.
          </p>
        )}
      </div>
      <div className="notifications-header">
        <div className="notifications-filters">
          <button 
            className={`filter-tab ${activeFilter === 'all' ? 'active' : ''}`}
            onClick={() => setActiveFilter('all')}
          >
            All <span className="count">{notifications.length}</span>
          </button>
          <button 
            className={`filter-tab ${activeFilter === 'unread' ? 'active' : ''}`}
            onClick={() => setActiveFilter('unread')}
          >
            Unread <span className="count">
              {notifications.filter(n => !n.read).length}
            </span>
          </button>
        </div>
        <div className="bulk-actions">
          <label className="select-all-checkbox filter-checkbox">
            <input
              type="checkbox"
              checked={selectAll}
              onChange={handleSelectAll}
            />
          </label>
          <button 
            className="bulk-action-btn"
            onClick={() => handleBulkMarkRead(true)}
            disabled={selectedNotifications.size === 0}
          >
            Mark as Read
          </button>
          <button 
            className="bulk-action-btn"
            onClick={() => handleBulkMarkRead(false)}
            disabled={selectedNotifications.size === 0}
          >
            Mark as Unread
          </button>
        </div>
      </div>

      <div className="notifications-list">
        {filteredNotifications.map((notification) => (
          <div 
            key={notification.key}
            className={`notification-item ${notification.read ? 'read' : ''}`}
            onClick={() => handleNotificationClick(notification)}
          >
            <div className="indicator-checkbox-column">
              <div className={`notification-indicator ${notification.type}`} />
              <label className="notification-checkbox filter-checkbox" onClick={e => e.stopPropagation()}>
                <input
                  type="checkbox"
                  checked={selectedNotifications.has(notification.key)}
                  onChange={(e) => handleCheckboxClick(e, notification.key)}
                />
              </label>
            </div>
            <div className="notification-content">
              <h3>{notification.title}</h3>
              <p className="address">{notification.property.base_info?.address}</p>
              <div className="price">
                {formatPrice(notification.property.base_price)}
                {notification.priceChange && (
                  <span className="price-change">
                    {formatPrice(notification.priceChange)}
                  </span>
                )}
              </div>
              <span className="time">{notification.time}</span>
            </div>
            <div className="notification-actions">
              <img 
                src={notification.property.imgSrc} 
                alt="Property"
                className="property-thumbnail"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NotificationsPanel; 