import React, { useState, useMemo, useEffect, useRef } from 'react';
import { PropertyScoring } from '../services/PropertyScoring';
import '../styles/PropertyList.css';

const STORAGE_KEY = 'propertyListDefaults';

const PropertyList = ({ 
  properties, 
  selectedProperty, 
  onPropertySelect, 
  onFilteredPropertiesChange,
  sortField,
  sortDirection,
  onSortChange,
  filters,
  onFiltersChange,
  legendFilter,
  onLegendFilterReset,
  limit,
  onLimitChange
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const filtersPanelRef = useRef(null);

  useEffect(() => {
    const defaults = JSON.parse(localStorage.getItem(STORAGE_KEY));
    if (defaults) {
      onLimitChange(defaults.limit || 100);
      onSortChange(defaults.sortField || 'score', defaults.sortDirection || 'desc');
      onFiltersChange(defaults.filters || {
        minPrice: '',
        maxPrice: '',
        minSqft: '',
        maxSqft: '',
        minComps: '',
        minScore: '',
        maxScore: '',
        minDays: '',
        maxDays: '',
        priceReduced: false,
        goldenOpportunities: false,
        newListings: false
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filtersPanelRef.current && 
          !filtersPanelRef.current.contains(event.target) && 
          !event.target.closest('.filter-button')) {
        setShowFilters(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const formatPrice = (price) => {
    return price ? price.toLocaleString() : '0';
  };

  const formatPercent = (value) => {
    return value ? (value * 100).toFixed(1) : '0';
  };

  const getValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc?.[part], obj) ?? 0;
  };

  const getScoreColor = (score) => {
    if (score >= 75) return '#FFD700'; // Gold
    if (score >= 50) return '#4CAF50'; // Green
    return '#FF5722'; // Orange/Red
  };

  const filteredAndSortedProperties = useMemo(() => {
    let result = [...properties];

    if (legendFilter) {
      result = result.filter(property => {
        const score = PropertyScoring.calculateOpportunityScore(property);
        const isGoldenOpportunity = score > 74;
        const isNewListing = property.base_info?.days_on_zillow <= 7;
        const isPriceReduced = property.base_info?.price_change < 0;

        switch (legendFilter) {
          case 'golden':
            return isGoldenOpportunity;
          case 'new':
            return !isGoldenOpportunity && isNewListing;
          case 'reduced':
            return !isGoldenOpportunity && !isNewListing && isPriceReduced;
          case 'standard':
            return !isGoldenOpportunity && !isNewListing && !isPriceReduced;
          default:
            return true;
        }
      });
    }

    result = result.filter(property => {
      if (filters.minPrice && property.base_price < parseFloat(filters.minPrice)) return false;
      if (filters.maxPrice && property.base_price > parseFloat(filters.maxPrice)) return false;
      if (filters.minSqft && property.base_info?.living_area < parseFloat(filters.minSqft)) return false;
      if (filters.maxSqft && property.base_info?.living_area > parseFloat(filters.maxSqft)) return false;
      if (filters.minComps && property.base_info?.sold_comparable_count < parseFloat(filters.minComps)) return false;
      
      const propertyScore = PropertyScoring.calculateOpportunityScore(property);
      if (filters.minScore && propertyScore < parseFloat(filters.minScore)) return false;
      if (filters.maxScore && propertyScore > parseFloat(filters.maxScore)) return false;
      
      if (filters.minDays && property.base_info?.days_on_zillow < parseFloat(filters.minDays)) return false;
      if (filters.maxDays && property.base_info?.days_on_zillow > parseFloat(filters.maxDays)) return false;
      
      if (filters.priceReduced && (!property.base_info?.price_change || property.base_info?.price_change >= 0)) return false;
      return true;
    });

    result.sort((a, b) => {
      let aValue, bValue;
      
      if (sortField === 'score') {
        aValue = PropertyScoring.calculateOpportunityScore(a);
        bValue = PropertyScoring.calculateOpportunityScore(b);
      } else {
        aValue = getValue(a, sortField);
        bValue = getValue(b, sortField);
      }
      
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    });

    return result.slice(0, limit);
  }, [properties, sortField, sortDirection, filters, limit, legendFilter]);

  useEffect(() => {
    onFilteredPropertiesChange(filteredAndSortedProperties);
  }, [filteredAndSortedProperties, onFilteredPropertiesChange]);

  const handleFilterChange = (key, value) => {
    onFiltersChange({
      ...filters,
      [key]: value
    });
  };

  const handleGoldenOpportunitiesChange = (checked) => {
    onFiltersChange({
      ...filters,
      goldenOpportunities: checked,
      minScore: checked ? '75' : '',
      maxScore: checked ? '100' : ''
    });
  };

  const handleNewListingsChange = (checked) => {
    onFiltersChange({
      ...filters,
      newListings: checked,
      minDays: checked ? '0' : '',
      maxDays: checked ? '7' : ''
    });
  };

  const clearFilters = () => {
    onFiltersChange({
      minPrice: '',
      maxPrice: '',
      minSqft: '',
      maxSqft: '',
      minComps: '',
      minScore: '',
      maxScore: '',
      minDays: '',
      maxDays: '',
      priceReduced: false,
      goldenOpportunities: false,
      newListings: false
    });
  };

  const handleSortFieldChange = (e) => {
    onSortChange(e.target.value, sortDirection);
  };

  const handleSortDirectionChange = () => {
    onSortChange(sortField, sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const saveAsDefaults = () => {
    const defaults = {
      limit,
      sortField,
      sortDirection,
      filters
    };
    localStorage.setItem(STORAGE_KEY, JSON.stringify(defaults));
    setShowFilters(false);
  };

  const resetToDefaults = () => {
    // Reset everything to defaults
    onLimitChange(100);
    onSortChange('score', 'desc');
    onFiltersChange({
      minPrice: '',
      maxPrice: '',
      minSqft: '',
      maxSqft: '',
      minComps: '',
      minScore: '',
      maxScore: '',
      minDays: '',
      maxDays: '',
      priceReduced: false,
      goldenOpportunities: false,
      newListings: false
    });
    // Reset legend filter
    if (onLegendFilterReset) {
      onLegendFilterReset();
    }
    setShowFilters(false);
  };

  return (
    <div className="property-list">
      <div className="property-list-header">
        <div className="filter-controls">
          <div className="left-controls">
            <div className="sort-controls">
              <select 
                className="filter-dropdown"
                value={sortField}
                onChange={handleSortFieldChange}
              >
                <option value="score">Score</option>
                <option value="base_info.percent_of_max_comp">% Max</option>
                <option value="base_price">Price</option>
                <option value="base_info.living_area">Sq Ft</option>
                <option value="base_info.sold_comparable_count">Comps</option>
                <option value="base_info.days_on_zillow">Days on Market</option>
              </select>
              <button 
                className="sort-direction"
                onClick={handleSortDirectionChange}
              >
                {sortDirection === 'asc' ? '↑' : '↓'}
              </button>
            </div>

            <button 
              className="filter-button"
              onClick={() => setShowFilters(!showFilters)}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3 17v2h6v-2H3zM3 5v2h10V5H3zm10 16v-2h8v-2h-8v-2h-2v6h2zM7 9v2H3v2h4v2h2V9H7zm14 4v-2H11v2h10zm-6-4h2V7h4V5h-4V3h-2v6z" fill="currentColor"/>
              </svg>
              Filters
            </button>

            <div className="limit-filter">
              <input
                type="number"
                className="limit-input"
                value={limit || ''}
                onChange={(e) => onLimitChange(Math.max(1, parseInt(e.target.value) || 100))}
                min="1"
                placeholder="Limit"
              />
            </div>
          </div>

          <button 
            className="reset-button"
            onClick={resetToDefaults}
            title="Reset to defaults"
          >
            ×
          </button>
        </div>

        {showFilters && (
          <div className="filters-panel" ref={filtersPanelRef}>
            <div className="filter-group">
              <label>Price Range</label>
              <div className="range-inputs">
                <input
                  type="number"
                  placeholder="Min"
                  value={filters.minPrice}
                  onChange={(e) => handleFilterChange('minPrice', e.target.value)}
                />
                <span>-</span>
                <input
                  type="number"
                  placeholder="Max"
                  value={filters.maxPrice}
                  onChange={(e) => handleFilterChange('maxPrice', e.target.value)}
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Square Feet</label>
              <div className="range-inputs">
                <input
                  type="number"
                  placeholder="Min"
                  value={filters.minSqft}
                  onChange={(e) => handleFilterChange('minSqft', e.target.value)}
                />
                <span>-</span>
                <input
                  type="number"
                  placeholder="Max"
                  value={filters.maxSqft}
                  onChange={(e) => handleFilterChange('maxSqft', e.target.value)}
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Number of Comps</label>
              <div className="range-inputs">
                <input
                  type="number"
                  placeholder="Min"
                  value={filters.minComps}
                  onChange={(e) => handleFilterChange('minComps', e.target.value)}
                  min="0"
                />
                <span>-</span>
                <input
                  type="number"
                  placeholder="Max"
                  value={filters.maxComps}
                  onChange={(e) => handleFilterChange('maxComps', e.target.value)}
                  min="0"
                />
              </div>
            </div>

            <div className="filter-group">
              <label>Score Range</label>
              <div className="range-inputs">
                <input
                  type="number"
                  placeholder="Min"
                  value={filters.minScore}
                  onChange={(e) => handleFilterChange('minScore', e.target.value)}
                  min="0"
                  max="100"
                />
                <span>-</span>
                <input
                  type="number"
                  placeholder="Max"
                  value={filters.maxScore}
                  onChange={(e) => handleFilterChange('maxScore', e.target.value)}
                  min="0"
                  max="100"
                />
              </div>
              <label className="filter-checkbox">
                <input
                  type="checkbox"
                  checked={filters.goldenOpportunities}
                  onChange={(e) => handleGoldenOpportunitiesChange(e.target.checked)}
                />
                Only include golden listings
              </label>
            </div>

            <div className="filter-group">
              <label>Days on Market</label>
              <div className="range-inputs">
                <input
                  type="number"
                  placeholder="Min"
                  value={filters.minDays}
                  onChange={(e) => handleFilterChange('minDays', e.target.value)}
                  min="0"
                />
                <span>-</span>
                <input
                  type="number"
                  placeholder="Max"
                  value={filters.maxDays}
                  onChange={(e) => handleFilterChange('maxDays', e.target.value)}
                  min="0"
                />
              </div>
              <label className="filter-checkbox">
                <input
                  type="checkbox"
                  checked={filters.newListings}
                  onChange={(e) => handleNewListingsChange(e.target.checked)}
                />
                Only include new listings
              </label>
            </div>

            <div className="filter-group checkboxes">
              <label>
                <input
                  type="checkbox"
                  checked={filters.priceReduced}
                  onChange={(e) => handleFilterChange('priceReduced', e.target.checked)}
                />
                Price Reduced Only
              </label>
            </div>

            <div className="filters-footer">
              <button className="clear-filters" onClick={clearFilters}>
                Clear Filters
              </button>
              <button className="save-defaults" onClick={saveAsDefaults}>
                Save as Default
              </button>
            </div>
          </div>
        )}

        <div className="results-count">
          Showing {filteredAndSortedProperties.length} properties
        </div>
      </div>
      
      <div className="property-list-content">
        {filteredAndSortedProperties.map((property) => {
          const isGoldenOpportunity = 
            property.base_info?.percent_of_max_comp < 0.4 && 
            property.base_info?.price_change < 0;
          
          // Use PropertyScoring service for consistent scoring
          const opportunityScore = Math.round(
            PropertyScoring.calculateOpportunityScore(property)
          );
          
          return (
            <div
              key={property.zpid}
              className={`property-card ${selectedProperty?.zpid === property.zpid ? 'selected' : ''} ${isGoldenOpportunity ? 'golden-opportunity' : ''}`}
              onClick={() => onPropertySelect(property)}
            >
              <img 
                src={property.imgSrc} 
                alt={property.base_info?.address} 
                className="property-image"
                onError={(e) => {
                  e.target.src = 'placeholder.jpg';
                }}
              />
              {property.base_info?.days_on_zillow <= 7 && (
                <div className="new-listing-badge">New Listing</div>
              )}
              <div className="property-info">
                <div className="property-price">
                  ${formatPrice(property.base_price)}
                  {property.base_info?.price_change < 0 && (
                    <span className="price-change negative">
                      -${Math.abs(property.base_info.price_change).toLocaleString()}
                    </span>
                  )}
                </div>
                <div className="property-address">
                  {property.base_info?.address}
                </div>
                <div className="property-details">
                  <span>{formatPrice(property.base_info?.living_area)} sqft</span>
                  <span>{formatPercent(property.base_info?.percent_of_max_comp)}% of max</span>
                  <span>{property.base_info?.sold_comparable_count} comps</span>
                  <span>{property.base_info?.days_on_zillow} days</span>
                </div>
                <div className="opportunity-score">
                  <svg className="score-circle" viewBox="0 0 36 36">
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke="#eee"
                      strokeWidth="3"
                    />
                    <path
                      d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                      fill="none"
                      stroke={getScoreColor(opportunityScore)}
                      strokeWidth="3"
                      strokeDasharray={`${opportunityScore}, 100`}
                    />
                    <text 
                      x="18" 
                      y="18" 
                      className="score-text" 
                      transform="rotate(90 18 18)"
                    >
                      {opportunityScore}
                    </text>
                  </svg>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PropertyList; 