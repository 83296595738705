import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { MarkerManager } from '../services/MarkerManager';
import '../styles/Map.css';

const Map = forwardRef(({ 
  properties, 
  selectedProperty, 
  onPropertySelect, 
  onLegendClick,
  legendFilter  // Add this prop
}, ref) => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const markerManagerRef = useRef(null);

  // Expose methods to parent component through ref
  useImperativeHandle(ref, () => ({
    getMarkerManager: () => markerManagerRef.current
  }));

  useEffect(() => {
    const initMap = () => {
      if (!mapInstanceRef.current && window.google) {
        const DC_CENTER = { lat: 38.9072, lng: -77.0369 };
        const MIN_ZOOM = 12;
        
        mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
          center: DC_CENTER,
          zoom: MIN_ZOOM,
          minZoom: MIN_ZOOM,
          maxZoom: 16,
          restriction: {
            latLngBounds: {
              north: 39.5,
              south: 38.5,
              east: -76.5,
              west: -77.5
            },
            strictBounds: false
          },
          styles: [
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{ visibility: 'off' }]
            }
          ],
          mapTypeControl: true,
          streetViewControl: true,
          fullscreenControl: true
        });

        // Initialize MarkerManager with the map instance
        markerManagerRef.current = new MarkerManager(mapInstanceRef.current);
      }
    };

    if (window.google) {
      initMap();
    } else {
      const script = document.createElement('script');
      console.log('API Key:', import.meta.env.VITE_GOOGLE_MAPS_API_KEY);
      script.src = `https://maps.googleapis.com/maps/api/js?key=${import.meta.env.VITE_GOOGLE_MAPS_API_KEY}`;
      script.async = true;
      script.onload = initMap;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    if (!mapInstanceRef.current || !markerManagerRef.current) return;
    
    // Clear existing markers before adding new ones
    markerManagerRef.current.clearAllMarkers();
    
    if (properties?.length) {
      markerManagerRef.current.initializeMarkers(properties, onPropertySelect);
    }
  }, [properties, onPropertySelect]);

  useEffect(() => {
    if (!mapInstanceRef.current || !markerManagerRef.current || !selectedProperty) return;
    markerManagerRef.current.showPropertyDetail(selectedProperty);
  }, [selectedProperty]);

  const handleLegendClick = (type) => {
    if (typeof onLegendClick === 'function') {
      onLegendClick(type);
    }
  };

  return (
    <div className="map-container">
      <div ref={mapRef} className="map" data-testid="map" />
      <div className="map-legend">
        <div 
          className={`legend-item ${legendFilter && legendFilter !== 'golden' ? 'dimmed' : ''}`}
          onClick={() => handleLegendClick('golden')}
          role="button"
          tabIndex={0}
        >
          <div className="legend-marker" style={{ background: '#FFD700' }}></div>
          <span>Golden Opportunity</span>
        </div>
        <div 
          className={`legend-item ${legendFilter && legendFilter !== 'new' ? 'dimmed' : ''}`}
          onClick={() => handleLegendClick('new')}
          role="button"
          tabIndex={0}
        >
          <div className="legend-marker" style={{ background: '#00BCD4' }}></div>
          <span>New Listing</span>
        </div>
        <div 
          className={`legend-item ${legendFilter && legendFilter !== 'reduced' ? 'dimmed' : ''}`}
          onClick={() => handleLegendClick('reduced')}
          role="button"
          tabIndex={0}
        >
          <div className="legend-marker" style={{ background: '#9C27B0' }}></div>
          <span>Price Reduced</span>
        </div>
        <div 
          className={`legend-item ${legendFilter && legendFilter !== 'standard' ? 'dimmed' : ''}`}
          onClick={() => handleLegendClick('standard')}
          role="button"
          tabIndex={0}
        >
          <div className="legend-marker" style={{ background: '#546E7A' }}></div>
          <span>Standard</span>
        </div>
      </div>
    </div>
  );
});

// Add display name for better debugging
Map.displayName = 'Map';

export default Map;