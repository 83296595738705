export class PropertyScoring {
    static calculateOpportunityScore(property) {
        const valuePosition = this.calculateValuePosition(property);
        const marketPosition = this.calculateMarketPosition(property);
        const propertyOptimization = this.calculatePropertyOptimization(property);
        
        return valuePosition + marketPosition + propertyOptimization;
    }

    static calculateConfidenceScore(property) {
        let score = 0;
        const base_info = property.base_info;

        // Add points for number of comps
        if (base_info?.sold_comparable_count >= 10) score += 40;
        else if (base_info?.sold_comparable_count >= 5) score += 25;
        else if (base_info?.sold_comparable_count >= 3) score += 15;

        // Add points for recent comps
        const recentComps = property.comp_info?.filter(comp => {
            const soldDate = new Date(comp.comp_dateSold);
            const monthsAgo = (new Date() - soldDate) / (1000 * 60 * 60 * 24 * 30);
            return monthsAgo <= 6;
        }).length;

        if (recentComps >= 5) score += 30;
        else if (recentComps >= 3) score += 20;
        else if (recentComps >= 1) score += 10;

        // Add points for similar comps
        const similarComps = property.comp_info?.filter(comp => {
            const sqftDiff = Math.abs(comp.comp_livingArea - base_info.living_area) / base_info.living_area;
            return sqftDiff <= 0.15;
        }).length;

        if (similarComps >= 5) score += 30;
        else if (similarComps >= 3) score += 20;
        else if (similarComps >= 1) score += 10;

        return score;
    }

    static calculateValuePosition(property) {
        let score = 0;
        const base_info = property.base_info;

        // Points for % of max comp (up to 40 points)
        if (base_info?.percent_of_max_comp <= 0.4) score += 40;
        else if (base_info?.percent_of_max_comp <= 0.5) score += 30;
        else if (base_info?.percent_of_max_comp <= 0.6) score += 20;
        else if (base_info?.percent_of_max_comp <= 0.7) score += 10;

        // Points for price per sqft comparison (up to 25 points)
        if (base_info?.percent_of_sqft <= 0.4) score += 15;
        else if (base_info?.percent_of_sqft <= 0.5) score += 10;
        else if (base_info?.percent_of_sqft <= 0.7) score += 5;
        else if (base_info?.percent_of_sqft <= 0.9) score += 0;

        return score;
    }

    static calculateMarketPosition(property) {
        let score = 0;
        const base_info = property.base_info;

        // Points for price reduction (up to 15 points)
        if (base_info?.price_change) {
            const priceReductionPercent = Math.abs(base_info.price_change) / property.base_price;
            if (priceReductionPercent >= 0.1) score += 15;
            else if (priceReductionPercent >= 0.05) score += 10;
            else if (priceReductionPercent >= 0.02) score += 5;
        }

        // Points for days on market (up to 10 points)
        if (base_info?.days_on_zillow >= 60) score += 10;
        else if (base_info?.days_on_zillow >= 30) score += 7;
        else if (base_info?.days_on_zillow >= 14) score += 5;

        return score;
    }

    static calculatePropertyOptimization(property) {
        let score = 0;
        
        // Price/sqft potential (5 points)
        const maxPricePerSqft = Math.max(...property.comp_info.map(comp => 
            comp.comp_price / comp.comp_livingArea));
        const currentPricePerSqft = property.base_price / property.base_info.living_area;
        const pricePerSqftGap = (maxPricePerSqft - currentPricePerSqft) / maxPricePerSqft;
        score += Math.min(pricePerSqftGap * 20, 5);
        
        
        // Market liquidity (5 points)
        score += Math.min(property.base_info.sold_comparable_count / 10 * 4, 5);
        
        // Image count potential (10 points)
        const imageCount = property.num_images || 0;
        if (imageCount <= 4) score += 10;
        else if (imageCount <= 8) score += 7;
        else if (imageCount <= 15) score += 4;
        else if (imageCount <= 12) score += 2;
        
        return score;
    }
}